import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getCurrentAccount, isModerator } from '../services/account';
import { createQuestion, getQuestion, getTranslation, updateQuestion } from '../services/services'
import { IQuestion, IQuestionState } from '../types';
import { Loader } from './Loader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { User } from '@microsoft/microsoft-graph-types';
import { useTranslation } from "react-i18next";
import { Header } from './Header';

export const QuestionDetail: React.FunctionComponent<{ user: User }> = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const id = params.questionId;
  const currentAccount = getCurrentAccount();
  const isAdmin = isModerator();
  const { user } = props;
  let displayName : string = "";
  if (user?.employeeId) {
    displayName = user.displayName as string;
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("questions:details.title.error.required")),
    description: Yup.string()
      .required(t("questions:details.description.error.required")),
    creatorDisplayName: Yup.string()
      .required(t("questions:details.creatorDisplayName.error.required")),
    titleEn: Yup.string()
      .required(t("questions:details.titleEn.error.required")),
    descriptionEn: Yup.string()
      .required(t("questions:details.descriptionEn.error.required")),
  });

  const [question, setQuestion] = useState<IQuestion>({
    title: "",
    description: "",
    creatorId: currentAccount?.localAccountId as string,
    creatorUpn: currentAccount?.username,
    creatorDisplayName: displayName
  });

  const { register, handleSubmit, getValues, setValue, formState: { errors }, reset } = useForm<IQuestion>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = handleSubmit((data) => {
    if (!id) {
      data.creatorId = currentAccount?.localAccountId as string;
      data.creatorUpn = currentAccount?.username;

      createQuestion(data).then(async (value) => {
        if (value.ok) {
          navigate('/');
        }
        else {
          alert(t("questions:details.errors.create"))
        }
      })
    }
    else {
      updateQuestion(data).then(async (value) => {
        if (value.ok) {
          navigate('/');
        }
        else {
          alert(t("questions:details.errors.update"))
        }
      })
    }
  });

  useEffect(() => {
    if (id) {
      getQuestion(id).then(async (res) => { return res.json() }).then((o) => setQuestion(o));
    }
  }, [id]);
  
  useEffect(() => {
    reset(question);
  }, [question, reset]);

  const translate = async (sourceField: any, targetField: any) => {
    const singleValue = getValues(sourceField);
    const translationResponse = await getTranslation(singleValue);
    if (translationResponse.ok) {
      const translation = await translationResponse.json();
      if (translation.length > 0 && translation[0].translations.length > 0) {
        setValue(targetField, translation[0].translations[0].text);
      }
    }
    else {
      alert(t("questions:details.errors.translate"));
    }
  }

  if (!question) return <Loader isLoading={!question} />;

  const isReadOnly = ((question.creatorId !== currentAccount?.localAccountId ? true : undefined || question.state === IQuestionState.Answered) && !isAdmin) || (question.state === IQuestionState.Answered && isAdmin);

  return (
    <div className='contents'>
        <Header header={t("questions:details.heading")} additionalCss="modules-background"/>
        <article className="message is-success">
          <div className="message-body" dangerouslySetInnerHTML={{ __html: t("questions:details.intro") }}>
          </div>
        </article>
        <form onSubmit={onSubmit}>
          {(!user?.employeeId) && (
            <div className="field">
              <label className="label is-small">{t("questions:details.creatorDisplayName.label")}</label>
              <div className="control">
                <input className="input is-small" {...register("creatorDisplayName", { disabled: isReadOnly})} />
              </div>
              {errors.creatorDisplayName?.message && (
                <p className="help is-danger is-small">{errors.creatorDisplayName?.message}</p>
              )}
            </div>
          )}
          <div className="field">
            <label className="label is-small">{t("questions:details.title.label")}</label>
            <div className="control">
              <input className="input is-small"  {...register("title", { disabled: isReadOnly})} />
            </div>
            {errors.title?.message && (
              <p className="help is-danger">{errors.title?.message}</p>
            )}
          </div>
          <div className="field">
            <label className="label is-small">{t("questions:details.description.label")}</label>
            <div className="control">
              <textarea className="textarea is-small" {...register("description", { disabled: isReadOnly})} rows={4} />
            </div>
            {errors.description?.message && (
              <p className="help is-danger">{errors.description?.message}</p>
            )}
          </div>
          <br />
          <button className="button is-small is-primary is-fullwidth" onClick={(e) => {
                                        e.preventDefault();
                                        translate("title", "titleEn");
                                        translate("description", "descriptionEn");
                                    }}>{t("questions:details.translation.text")}</button>
                                    <br />
          <h3 className='title is-4'>{t("questions:details.headingEnglish")}</h3>
          
          <div className="field">
              <label className="label is-small">{t("questions:details.titleEn.label")}</label>
              <div className="control">
                <input className="input is-small" {...register("titleEn", { disabled: isReadOnly})} />
              </div>
              {errors.titleEn?.message && (
                    <p className="help is-danger">{errors.titleEn?.message}</p>
                )}
          </div>
          
          
          <div className="field">
            <label className="label is-small">{t("questions:details.descriptionEn.label")}</label>
            <div className="control">
              <textarea className="textarea is-small" {...register("descriptionEn", { disabled: isReadOnly})} rows={4} />
            </div>
            {errors.descriptionEn?.message && (
              <p className="help is-danger">{errors.descriptionEn?.message}</p>
            )}
          </div>
          <div className='footerButtons'>
              <div className='buttons is-pulled-right'>
                <Link to="/" className='button is-small'>
                  {t("questions:details.buttons.back")}
                </Link>
                {!isReadOnly && (
                  <button type="submit" className="button is-link is-small">{t("questions:details.buttons.save")}</button>
                )}
              </div>
          </div>
        </form>
    </div>
  );
};
