import React from "react"
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ResourcePicture: React.FunctionComponent<{
  isLoading: boolean
  image?: string
}> = (props) => {
  const { isLoading, image } = props;

  if (isLoading)
    return <FontAwesomeIcon className="fa-spin" icon={faSpinner}/>;

  if (isLoading === false && !image)
    return <React.Fragment />;

  if (!image) {
    return <React.Fragment />
  }
  return <img src={image} alt=""/>
}