import React from "react"
import { ResourcePhoto } from "./ResourcePhoto"

export const PeoplePhoto: React.FunctionComponent<{
  peopleId: string
  displayName: string
}> = (props) => {
  const { peopleId, displayName } = props;
  return (
    <ResourcePhoto
      key={peopleId}
      id={peopleId}
      displayName={displayName}
      resource="users"
    />
  )
}
