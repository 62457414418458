import React, { useEffect, useState } from 'react';
import { faThumbsUp as regularThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createVote, hasVoted, removeVote } from '../services/services';

export const Vote: React.FunctionComponent<{ id: string, votes: number } > = (props) => {
  const { id } = props;
  const [votes, setVotes] = useState<number>(props.votes);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isVoted, setIsVoted] = useState<boolean>(false);

  useEffect(() => {
      hasVoted(id).then(async (value) => {
        if (value.ok) {
          const result = await value.text();
          if (result === "true" || result === "True") {
            setIsVoted(true)
          }
        }
        setIsLoading(false);
      })
  }, [id]);

  const vote = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (isLoading)
      return;

    e.preventDefault();
    if (isVoted) {
      setVotes(votes - 1);
      removeVote(id);
    }
    else {
      setVotes(votes + 1);
      createVote(id);
    }
    setIsVoted(!isVoted);
  }
  
  const showVoted = isVoted && votes !== 0;

  return (
    <React.Fragment>
      <FontAwesomeIcon className='pointer' onClick={vote} icon={showVoted ? faThumbsUp : regularThumbsUp} />&nbsp;{votes}
    </React.Fragment>
  );
};
