import { msalInstance } from "../index";

export const getCurrentAccount = () => {
    if (!msalInstance)
        return null;

    const accounts = msalInstance.getAllAccounts();
    return Array.isArray(accounts) && accounts.length > 0 ? accounts[0] : null;
}
  
export const isModerator = () => {
    const currentAccount = getCurrentAccount();
    if (currentAccount?.idTokenClaims && (currentAccount?.idTokenClaims as any)?.roles) {
      const roles = (currentAccount?.idTokenClaims as any)?.roles;
      return Array.isArray(roles) && roles.includes("Moderator.ReadWrite");
    }
    return false;
}