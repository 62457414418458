import React from 'react';
import "./Loader.css"

export const Loader: React.FunctionComponent<{ isLoading?: boolean }> = (props) => {
  const { isLoading } = props;
  
  return (
    <div className={`loader-wrapper ${isLoading || isLoading === undefined ? 'is-active' : ''}`}>
        <div className="loader is-loading"></div>
    </div>
  );
};
