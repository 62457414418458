import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { getCurrentAccount } from '../services/account'
import logo from "../_images/logo.svg";
import { msalInstance } from "../index";
import { useTranslation } from 'react-i18next';

export const Layout: React.FunctionComponent<{ children?: React.ReactNode, }> = (
  props
) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { t } = useTranslation();
  const { children } = props;

  const currentAccount = getCurrentAccount();
  const userName = currentAccount?.username;

  return (
    <div className="app-container stretchedToMargin">
      <header>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item logoNavBarItem" to={"/"}>
              <span className='logoContainer'>
                <img className='logo' src={logo} alt="EasyLife365" />
              </span>
              &nbsp;&nbsp;Your Questions, please!
            </Link>

            <a role="button" className="navbar-burger burger" onClick={toggle} aria-label="menu" aria-expanded="false" data-target="mainNavBar">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="mainNavBar" className={`navbar-menu  ${isOpen ? "is-active" : ""}`}>
            <div className="navbar-start">
              <NavLink className="navbar-item" to={"/"}>
                {t("layout:navigation.allQuestions")}
              </NavLink>
              <NavLink className="navbar-item" to={"/answered"}>
                {t("layout:navigation.answered")}
              </NavLink>
              <NavLink className="navbar-item" to={"/topUnanswered"}>
                {t("layout:navigation.topUnanswered")}
              </NavLink>
            </div>
            {userName && (
              <div className="navbar-end">
                <div className="navbar-item has-dropdown is-hoverable">
                  <a className="navbar-link" href="#" onClick={(e) => e.preventDefault()}>{userName}</a>
                  <div className="navbar-dropdown">
                    <a href="#" className="navbar-item" onClick={(e) => { e.preventDefault(); msalInstance.logoutRedirect() }}>
                      Log out
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </nav>
      </header>
      <div className="app-body container">{children}</div>
    </div>
  );
};
