import React from "react"
import { Acronym } from "./Acronym"
import useSWR from "swr";
import { getResourcePhoto } from "../services/services";

const fetcher = async (resource: string, id: string): Promise<string> => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      let results = await getResourcePhoto(resource, id);
      resolve(results);
    }
    catch (ex) {
      reject("error")
    }
  })
};

export const ResourcePhoto: React.FunctionComponent<{
  id: string
  resource: string
  displayName: string
}> = (props) => {
  const { data: image, error } = useSWR<string>(`el.photo.${props.resource}.${props.id}`, () => fetcher(props.resource, props.id));

  let component = <React.Fragment />;
  if (error === "error") {
    component = <Acronym title={props.displayName}></Acronym>
  }
  else if (image) {
    component = <img src={image} alt="" />
  }

  return component;
}
