import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import * as msal from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import i18next from "i18next";
import questions_de from "./translations/de/questions.json";
import questions_en from "./translations/en/questions.json";
import layout_de from "./translations/de/layout.json";
import layout_en from "./translations/en/layout.json";
import { I18nextProvider } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import 'bulma/css/bulma.css'

export const resources = {
  en: {
    questions: questions_en,
    layout: layout_en
  },
  de: {
    questions: questions_de,
    layout: layout_de
  }
}

i18next.use(LanguageDetector).init(
  {
    interpolation: { escapeValue: false },
    detection: {
      order: ["querystring", "navigator"],
    },
    lng: 'en',
    ns: ['questions', 'layout'],
    resources,
    fallbackLng: "en",
  }
);

const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_AUTH_CLIENTID}`,
        authority: `${process.env.REACT_APP_AUTH_AUTHORITY}`,
        redirectUri: '/'
    }
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <I18nextProvider i18n={i18next}>
           <App />
          </I18nextProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
