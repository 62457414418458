export interface IQuestion {
    key?: string,
    created?: string,
    createdby?: string,
    modified?: string,
    modifiedBy?: string,
    title: string,
    description: string,
    titleEn?: string,
    descriptionEn?: string,
    creatorId: string,
    creatorUpn?: string,
    creatorDisplayName?: string,
    votes?: number,
    state?: IQuestionState,
    answer?: IAnswer
}

export interface INotificationSettings {
    language: string,
    notifyNewQuestions: boolean,
    notifyAnsweredQuestions: boolean
}

export enum IQuestionState {
    Pending = 0,
    Answered = 1
}

export interface IAnswer {
    answeredById?: string,
    answeredByDisplayName?: string,
    url?: string,
    answerDate?: string
}