import React, { useState } from 'react';
import useSWR, { Fetcher } from 'swr'
import { getQuestions } from '../services/services'
import { IQuestion, IQuestionState } from '../types';
import { useTable, useSortBy } from 'react-table'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { faDownLong, faUpLong, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from './Loader'
import { Vote } from "./Vote"
import { useTranslation } from 'react-i18next';
import { Header } from './Header';
import { PeoplePhoto } from './PeoplePhoto';
import { FollowPreferences } from './FollowPreferences';

enum IQuestionFilter {
  All,
  Answered,
  TopUnanswered,
}

const getTitle = (mode: IQuestionFilter) => {
  if (mode === IQuestionFilter.Answered) {
    return "layout:navigation.answered";
  }
  else if (mode === IQuestionFilter.TopUnanswered) {
    return "layout:navigation.topUnanswered";
  }
  return "layout:navigation.allQuestions"
}

// IMPORTANT: treating ascending and descending in the inverse order
const getSortBy = (mode: IQuestionFilter) => {
  if (mode === IQuestionFilter.Answered) {
    return [{ id: 'answeredByDate', desc: false }]
  }
  else if (mode === IQuestionFilter.TopUnanswered) {
    return [{ id: 'votes', desc: false }]
  }
  return [{ id: 'created', desc: false }]
}

const sortDate = (rowA: any, rowB: any, columnId: string) => {
  try {
    if (!rowA || !rowA.original[columnId]) return -1;
    if (!rowB || !rowB.original[columnId]) return 1;

    let valueA = rowA.original[columnId];
    let valueB = rowB.original[columnId];

    const dateA = new Date(valueA);
    const dateB = new Date(valueB);

    return dateB.getTime() - dateA.getTime();
  } catch (error) {
    console.error(error);
    return 0;
  }
};

const sortAnswer = (rowA: any, rowB: any) => {
  try {
    if (!rowA || !rowA.original["answer"]) return 1;
    if (!rowB || !rowB.original["answer"]) return -1;

    if (!rowA || !rowA.original["answer"]["answerDate"]) return 1;
    if (!rowB || !rowB.original["answer"]["answerDate"]) return -1;

    let valueA = rowA.original["answer"]["answerDate"];
    let valueB = rowB.original["answer"]["answerDate"];

    const dateA = new Date(valueA);
    const dateB = new Date(valueB);

    return dateB.getTime() - dateA.getTime();
  } catch (error) {
    console.error(error);
    return 0;
  }
};

const sortVotes = (rowA: any, rowB: any, columnId: string) =>  {
  if (rowA === undefined || !rowA.original[columnId]) return 1;
  if (rowB === undefined || !rowB.original[columnId]) return -1;
  var valueA = rowA.original[columnId];
  var valueB = rowB.original[columnId];

  return valueA < valueB ? 1 : -1;
}

const getQuestionFilter = (mode: string | undefined) => {
    if (mode === "topUnanswered") {
      return IQuestionFilter.TopUnanswered;
    } else if (mode === "answered") {
      return IQuestionFilter.Answered;
    }
    return IQuestionFilter.All;
}

const fetcher: Fetcher<string, IQuestion[]> = () => getQuestions().then(async (res) => { return res.json() });

export const QuestionOverview: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { data, error } = useSWR("retrieveQuestions", fetcher, { dedupingInterval: 2000 });
  const navigate = useNavigate();
  const { mode } = useParams<"mode">();
  const [showModal, setShowModal] = useState<boolean>(false);

  const filterMode = getQuestionFilter(mode); 

  const columns = React.useMemo(
    () => {
      let headings = [
        {
          Header: 'Key',
          accessor: 'key'
        },
      ]
      headings.push({
        Header: t("questions:overview.table.header.question"),
        id: 'title',
        accessor: (data: any) => {
          const inputData = data as IQuestion;
          return <Link key={`link${inputData.key}`} to={`/questions/${inputData.key}`}>{inputData.title}</Link>
        },
        sortType: (rowA: any, rowB: any, columnId: string) => sortVotes(rowA, rowB, columnId) 
      } as any);

      headings.push({
        Header: t("questions:overview.table.header.createdBy"),
        id: 'creatorDisplayName',
        accessor: (data: any) => {
          const inputData = data as IQuestion;
          if (inputData.creatorDisplayName) {
            return inputData.creatorDisplayName;
          }
          return "";
      },
        sortType: "basic" //(rowA: any, rowB: any, columnId: string, desc: boolean) => sortVotes(rowA, rowB, columnId, desc) 
      } as any);

      headings.push({
        Header: t("questions:overview.table.header.created"),
        id: 'created',
        accessor: (data: any) => {
          const inputData = data as IQuestion;

          if (inputData.created) {
            var date = new Date(inputData.created);
            return date.toLocaleDateString();
          }
          return "";
        },
        sortType: (rowA: any, rowB: any, columnId: string) => sortDate(rowA, rowB, columnId) 
      } as any);

      headings.push({
        Header: t("questions:overview.table.header.votes"),
        id: "votes",
        accessor: (data: any) => {
          const inputData = data as IQuestion;
          return <Vote votes={inputData.votes as number} key={`votes${inputData.key}`} id={inputData.key as string}/>
        },
        sortType: (rowA: any, rowB: any, columnId: string) => sortVotes(rowA, rowB, columnId)
      } as any);

      if (filterMode !== IQuestionFilter.TopUnanswered) {
        headings.push({
          Header: t("questions:overview.table.header.answer"),
          id: "answeredByDate",
          accessor: (data: any) => {
            const inputData = data as IQuestion;
            const answerLink = inputData.answer?.url as string
            const anseredByDisplayName = inputData.answer?.answeredByDisplayName as string
            const personId = inputData.answer?.answeredById as string;
            var dateString = undefined;
            if (inputData.answer?.answerDate) {
              var date = new Date(inputData.answer?.answerDate)
              dateString = date.toLocaleDateString();
            }
  
            if (anseredByDisplayName && personId && answerLink) {
              return  <article className="media" key={`answerColumn${inputData.key}`}>
                        <div className="media-left">
                          <figure className="image is-64x64 template-square">
                            <PeoplePhoto peopleId={personId} displayName={anseredByDisplayName} />
                          </figure>
                        </div>
                        <div className="media-content">
                          <div className="content">
                            <p>
                              <strong>{anseredByDisplayName}</strong><br />
                              <small>{dateString}</small><br /><a href={answerLink} target="_blank"><FontAwesomeIcon className='pointer' icon={faLink} />&nbsp;{t("questions:overview.table.body.readanswer")}</a>
                            </p>
                          </div>
                        </div>
                      </article>
            }
            return "";
          },
          sortType: (rowA: any, rowB: any) => sortAnswer(rowA, rowB) 
        } as any);
      } 

      return headings;
    },
    [t, filterMode]
  )
  const title = t(getTitle(filterMode))

  const inputData = React.useMemo(() => {
    if (!data || error)
      return [];
      
    var questions = data as unknown as IQuestion[];
    if (filterMode === IQuestionFilter.TopUnanswered)
      return questions.filter((p) => p.state === IQuestionState.Pending) as any;

    if (filterMode === IQuestionFilter.Answered)
      return questions.filter((p) => p.state === IQuestionState.Answered) as any;
    
    return questions as any
  }, [data, filterMode, error])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data: inputData,
      initialState: {
        hiddenColumns: ["key"],
        sortBy: getSortBy(filterMode)
      }
    },
    useSortBy
  );

  if (error) return <React.Fragment>An error has occurred.</React.Fragment>;
  if (!data) return <Loader isLoading={!data} />
  
  return (
    <div className='contents'>
      <FollowPreferences setShowModal={setShowModal} showModal={showModal} />
      <Header header={title} additionalCss="modules-background"/>
      <div className='buttons is-pulled-right'>
        <button className='button is-small' onClick={() => setShowModal(true)}>{t("questions:overview.buttons.follow.text")}</button>

        <button className='button is-primary is-small' onClick={() => { 
            navigate("/questions/create");
          }}>{t("questions:overview.buttons.new.text")}</button>
      </div>
      <table className='table is-fullwidth' {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps((column as any).getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span className='sorting'>
                    {/* IMPORTANT: treating descending and ascending in the inverse order */}
                    {(column as any).isSorted
                      ? (column as any).isSortedDesc
                        ? <React.Fragment>&nbsp;&nbsp;<FontAwesomeIcon className='pointer' icon={faUpLong} /></React.Fragment>
                        : <React.Fragment>&nbsp;&nbsp;<FontAwesomeIcon className='pointer' icon={faDownLong} /></React.Fragment>
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
