import "./Header.css"

import React from "react"

export const Header: React.FunctionComponent<{
  header: string
  subTitle?: string
  additionalCss?: string
}> = (props) => {
  let css = "header-container"
  if (props.additionalCss) {
    css += " " + props.additionalCss
  }

  return (
    <div className={css}>
      {props.header && <h1 className="title is-3">{props.header}</h1>}
      {props.children && <div>{props.children}</div>}
      {props.subTitle && (
        <div className="header-container-subtitle">{props.subTitle}</div>
      )}
    </div>
  )
}