import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCurrentAccount, isModerator } from '../services/account';
import { createAnswer } from '../services/services'
import { IAnswer} from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { User } from '@microsoft/microsoft-graph-types';
import { useTranslation } from 'react-i18next';
import { PeoplePicker } from './PeoplePicker';
import { Header } from './Header';

export const AnswerDetail: React.FunctionComponent<{ id?: string, answer?: IAnswer, user: User} > = (props) => {
  const navigate = useNavigate();
  const isAdmin = isModerator();
  const currentAccount = getCurrentAccount();
  const { id, answer, user } = props;
  const { t } = useTranslation();
  
  const validationSchema = Yup.object().shape({
    url: Yup.string().url(t("questions:answermask.url.errors.invalid"))
  });

  const { register, handleSubmit, setValue, getValues, formState: { errors }, reset } = useForm<IAnswer>({
    defaultValues: answer,
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    reset(answer);
  }, [answer, reset]);

  if (!id || !isAdmin) {
    return <React.Fragment />
  }
  
  const onSubmitAnswer = handleSubmit((data) => {
    if (!data.answeredById) {
      data.answeredById = currentAccount?.localAccountId as string;
      data.answeredByDisplayName = user.displayName as string;
    }
    createAnswer(id, data).then(async (value) => {
      if (value.ok) {
        navigate('/');
      }
      else {
        alert(t("questions:answermask.notabletosave"))
      }
    })
  });

  const toIsoString = (date: Date) => {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num: number) {
            return (num < 10 ? '0' : '') + num;
        };
  
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
  }

  const dateValue = getValues("answerDate");

  const addUser = async (passedUser: User) => {
    setValue("answeredById", passedUser.id)
    setValue("answeredByDisplayName", passedUser.displayName as string)
  };

  return (
    <React.Fragment>
      <div className='contents'>
        <Header header={t("questions:answermask.header")} additionalCss="modules-background"/>
        <form key="answerForm" onSubmit={onSubmitAnswer}>
          
        <div className="field">
          <label className="label is-small">{t("questions:answermask.url.label")}</label>
          <div className="control">
            <input className="input is-small" {...register("url")} />
          </div>
          {errors.url?.message && (
            <p className="help is-danger">{errors.url?.message}</p>
          )}
        </div>
        <div className="field">
          <label className="label is-small">{t("questions:answermask.date.label")}</label>
          <div className="control">
            <DayPickerInput inputProps={{
              class: 'input is-small'
            }} 
            placeholder='Select a date'
            formatDate={(date: Date, format: string, locale:string) => {
              return date.toLocaleDateString()
            }}
            format="LL"
            value={dateValue ? new Date(dateValue) : undefined} onDayChange={day => {
              if (day) {
                setValue("answerDate", toIsoString(day))
              }
              else {
                setValue("answerDate", undefined) 
              }
            }} />
          </div>
          {errors.answerDate?.message && (
            <p className="help is-danger">{errors.answerDate?.message}</p>
          )}
        </div>
        <div className="field">
          <label className="label is-small">{t("questions:answermask.answeredBy.label")}</label>
          <PeoplePicker itemSelected={addUser} t={t} />
          <div className="control">
            <input className="input is-small" {...register("answeredByDisplayName")} disabled={true} />
          </div>
          {errors.url?.message && (
            <p className="help is-danger">{errors.url?.message}</p>
          )}
        </div>
        

        <div className='footerButtons'>
          <div className="buttons is-pulled-right">
              <Link to={`/questions/${id}`} className='button is-small'>
                {t("questions:answermask.buttons.back.text")}
              </Link>
              <button type="submit" className="button is-primary is-small">{t("questions:answermask.buttons.save.text")}</button>
          </div>
        </div>
      </form>
      </div>
    </React.Fragment>
  );
};
