import React, { useRef } from 'react';
import "./PeoplePicker.css";
import { Loader } from "./Loader";
import { User } from "@microsoft/microsoft-graph-types";
import { ResourcePicture } from './ResourcePicture';
import { b64toBlob, blobToBase64, getPeoplePhotos, getUserBySearchQuery } from '../services/services';

interface IUserWithPicture {
  id?: string,
  displayName?: string,
  mail?: string,
  userType?: string,
  userPrincipalName?: string,
  isLoading: boolean,
  picture?: string
}

export const PeoplePicker: React.FunctionComponent<{
  itemSelected?: (user: User) => void;
  userIdToExclude?: string;
  disabled?: boolean;
  skipAccountEnabled?: boolean,
  t(translation: string): any;
}> = (props) => {
  const filterRef = useRef();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [triggerSearch, setTriggerSearch] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<IUserWithPicture[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  React.useEffect(() => {
    let delayTimeOutFunction: any;

    if (!filterRef.current) {
      (filterRef as any).current = true;

    } else { // componentDidMount equivalent
      delayTimeOutFunction = setTimeout(() => {
        if (searchTerm === "" || !searchTerm) {
          setSearchResults([]);
          setTriggerSearch(false);
          return;
        }

        setIsLoading(true);
        setTriggerSearch(true);

        if (!searchTerm || searchTerm?.trim() === "") {
          setSearchResults([])
        }
        else {
          getUserBySearchQuery(searchTerm, "id,mail,displayName,userType,userPrincipalName", 10).then((results) => {
              const users: [User] = results.value
              const peopleList = users.map((person: any) => {
                const result: IUserWithPicture = {
                  id: person.id,
                  mail: person.mail ? person.mail : undefined,
                  displayName: person.displayName ? person.displayName : undefined,
                  userType: person.userType ? person.userType : undefined,
                  userPrincipalName: person.userPrincipalName ? person.userPrincipalName : undefined,
                  isLoading: true,
                  picture: undefined
                }
                return result;
              })

              const peopleArray = peopleList.map((person: any) => person.id as string);

              if (peopleArray.length > 0) {
                getPeoplePhotos(peopleArray).then((contents) => {
                  const results = peopleList.map(async (person) => {
                    person.isLoading = false;
                    const response = contents.getResponseById(person.id as string);
                    if (response.ok) {
                      let data = await response.text();
                      const _binToBlob = await b64toBlob((data), 'img/jpg');
                      const blobUrl = await blobToBase64(_binToBlob);
                      person.picture = blobUrl;
                    }

                    return person;
                  });

                  Promise.all(results).then((list) => setSearchResults(list));
                })
              }
              setSearchResults(peopleList);
            })
            .catch(() => setSearchResults([]))
            .finally(() => setIsLoading(false));
        }
      }, 350); // denounce delay
    }
    return () => clearTimeout(delayTimeOutFunction);
  }, [searchTerm]);

  return (
    <div
      className="picker-container"
      onFocus={() => setTriggerSearch(false)}
      onBlur={() => {
        setSearchResults([]);
        setSearchTerm("");
        setTriggerSearch(false);
      }}
    >
      <div className="field">
        <div className="control">
          <input
            type="text"
            className="input is-small"
            disabled={props.disabled === true}
            placeholder={props.t("questions:answermask.answeredBy.placeholder")}
            value={searchTerm}
            onChange={handleChange}
          />
        </div>
      </div>

      {triggerSearch && (
        <ul className="picker-container-list">
          {searchResults.map((item, i) => (
            <li
              key={i}
              className="picker-container-list-item"
              onMouseDown={() => {
                if (props.itemSelected) {
                  props.itemSelected(item);
                }
                setSearchResults([]);
                setSearchTerm("");
                setTriggerSearch(false);
              }}
            >
              <div className="picker-container-list-image">
                <ResourcePicture isLoading={item.isLoading} image={item.picture}></ResourcePicture>
              </div>
              <div className="picker-container-list-text">
                <p className="picker-container-list-text-primary">
                  {item.displayName}{" "}
                </p>
                <p className="picker-container-list-text-secondary">
                  {item.mail ? item.mail : item.userPrincipalName}
                </p>
              </div>
            </li>
          ))}
          <Loader isLoading={isLoading}/>
          {searchResults.length === 0 && isLoading === false && (
            <li className="picker-container-list-noItems">
              {props.t("questions:answermask.answeredBy.noresults")}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
