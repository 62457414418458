import { msalInstance } from "../index";
import { getCurrentAccount } from "./account";
import { IAnswer, INotificationSettings, IQuestion } from "../types"
import "isomorphic-fetch"; // or import the fetch polyfill you installed
import { AuthProvider, AuthProviderCallback, BatchRequestContent, BatchRequestStep, BatchResponseContent, Client, Options } from "@microsoft/microsoft-graph-client";
import { User } from "@microsoft/microsoft-graph-types";

export const getGraphClient = async () => {
    const authProvider: AuthProvider = async (callback: AuthProviderCallback) => {
        try {
            const token = await getToken(process.env.REACT_APP_GRAPH_SCOPE as string);
            callback(null, token);
        
        }
        catch (ex) {
            callback(ex, "");
        }
    };
    const options: Options = {
        authProvider,
    };
    return Client.init(options);
}

export const b64toBlob = async (b64Data: any, contentType: string, sliceSize?: number): Promise<Blob> => {
    contentType = contentType || 'image/png';
    sliceSize = sliceSize || 512;

    let byteCharacters: string = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

export const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = _ => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  };

export const getPeoplePhotos = async (personIds: string[]): Promise<BatchResponseContent> => {
    return await getResourcePhotos("users", personIds);
};

export const getResourcePhoto = async (
    resource: string,
    resourceId: string
  ): Promise<string> => {
    return new Promise<string>(async (resolve, reject) => {
        const client = await getGraphClient();

      try {
        let blob = await client
          .api(`/${resource}/${resourceId}/photo/$value`)
          //.responseType(ResponseType.BLOB)
          .get();
        //let response = await this.blobToBase64(blob);
        resolve(window.URL.createObjectURL(blob));
      } catch (error) {
        reject(undefined);
      }
    });
  };

export const getResourcePhotos = async (
    resource: string,
    resourceIds: string[]
  ): Promise<BatchResponseContent> => {
    return new Promise<BatchResponseContent>(async (resolve, reject) => {
      const client = await getGraphClient();

      try {
        const requests: BatchRequestStep[] = resourceIds.map((resourceId) => {
          const request: BatchRequestStep = {
            id: resourceId,
            request: new Request(`/${resource}/${resourceId}/photos/64x64/$value`, {
              method: "GET",
            })
          };
          return request;
        })

        const batchRequestContent = new BatchRequestContent(requests);
        const content = await batchRequestContent.getContent();
        const response = await client.api("/$batch").post(content);
        resolve(new BatchResponseContent(response));
      } catch (error) {
        console.error(error);
        reject(null);
      }
    });
};

const endpointUrl = `${process.env.REACT_APP_BACKEND_SERVICE_URL}`

export const getMe = async (): Promise<User> => {
    const client = await getGraphClient();
    return client.api("/me").select("id,displayName,userPrincipalName,mail,employeeId").get()
};

export const getUserBySearchQuery = async (
    search: string,
    select: string,
    limit?: number,
    additionalFilter?: string,
  ): Promise<any> => {
    const client = await getGraphClient();

    let filter = "userType eq 'Member'";

    if (additionalFilter) 
      filter += additionalFilter;
    
    const searchDisplayName = `"displayName:${encodeURI(search)}"`
    const searchMail = `"mail:${encodeURI(search)}"`
    const searchGivenName = `"givenName:${encodeURI(search)}"`
    const searchSurname = `"surName:${encodeURI(search)}"`
    const otherMails = `"otherMails:${encodeURI(search)}"`
    const userPrincipalName = `"userPrincipalName:${encodeURI(search)}"`
    const company = `"companyName:${encodeURI(search)}"`

    var query = client
      .api("/users")
    if (filter) {
      query = query.filter(filter)
    }
    query = query.orderby("displayName")
      .count(true)

    if (search && search && search?.trim() !== "") 
      query = query.search(searchDisplayName + " OR " + searchMail + " OR " + searchGivenName + " OR " + searchSurname + " OR " + otherMails + " OR " + userPrincipalName + " OR " + company).header("ConsistencyLevel","Eventual")
    
    if (limit) {
      query = query.top(limit)
    }
    query.expand("extensions($filter = startsWith(id, 'cloud.easyLife'))")
    return query.select(select).get()
  };

const getToken = async (scope: string) => {
    const account = getCurrentAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        scopes: [`${scope}`],
        account: account
    });

    return response.accessToken;
}

const getHeader = async (scope: string, inputHeader?: Headers) => {
    const token = await getToken(scope);
    const headers = inputHeader ?? new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    return headers;
}

export const getGraphHeader = async (inputHeader?: Headers) => {
    return await getHeader(process.env.REACT_APP_GRAPH_SCOPE as string, inputHeader);
}

export const getServiceHeader = async (inputHeader?: Headers) => {
    return await getHeader(process.env.REACT_APP_BACKEND_SERVICE_SCOPE as string, inputHeader);
}

export const getNotificationsettings = async () => {
  const url = `${endpointUrl}notifications`

  const header = await getServiceHeader();
  const options = {
      method: "GET",
      headers: header
  };

  return fetch(url, options);
}

export const updateNotificationSettings = async (notification: INotificationSettings) => {
  const url = `${endpointUrl}notifications`

  const header = await getServiceHeader();
  const options = {
      method: "PATCH",
      headers: header,
      body: JSON.stringify(notification)
  };

  return fetch(url, options);
}

export const getQuestions = async () => {
    const url = `${endpointUrl}questions`

    const header = await getServiceHeader();
    const options = {
        method: "GET",
        headers: header
    };

    return fetch(url, options);
}

export const getQuestion = async (id: string) => {
    const url = `${endpointUrl}questions/${id}`

    const header = await getServiceHeader();
    const options = {
        method: "GET",
        headers: header
    };

    return fetch(url, options);
}

export const updateQuestion = async (question: IQuestion) => {
    const url = `${endpointUrl}questions/${question.key}`

    const header = await getServiceHeader();
    const options = {
        method: "PATCH",
        headers: header,
        body: JSON.stringify(question)
    };

    return fetch(url, options);
}

export const getTranslation = async (input: string) => {
    const url = `${endpointUrl}translate`

    const header = await getServiceHeader();
    const options = {
        method: "PATCH",
        headers: header,
        body: JSON.stringify({
            text: input
        })
    };

    return fetch(url, options);
}

export const createQuestion = async (question: IQuestion) => {
    const url = `${endpointUrl}questions`

    const header = await getServiceHeader();
    const options = {
        method: "POST",
        headers: header,
        body: JSON.stringify(question)
    };

    return fetch(url, options);
}

export const removeQuestion = async (id: string) => {
    const url = `${endpointUrl}questions/${id}`

    const header = await getServiceHeader();
    const options = {
        method: "DELETE",
        headers: header
    };

    return fetch(url, options);
}

export const createAnswer = async (id: string, answer: IAnswer) => {
    const url = `${endpointUrl}questions/${id}/answer`

    const header = await getServiceHeader();
    const options = {
        method: "POST",
        headers: header,
        body: JSON.stringify(answer)
    };

    return fetch(url, options);
}

export const hasVoted = async (id: string) => {
    const url = `${endpointUrl}questions/${id}/vote`

    const header = await getServiceHeader();
    const options = {
        method: "GET",
        headers: header
    };

    return fetch(url, options);
}

export const createVote = async (id: string) => {
    const url = `${endpointUrl}questions/${id}/vote`

    const header = await getServiceHeader();
    const options = {
        method: "POST",
        headers: header
    };

    return fetch(url, options);
}

export const removeVote = async (id: string) => {
    const url = `${endpointUrl}questions/${id}/vote`

    const header = await getServiceHeader();
    const options = {
        method: "DELETE",
        headers: header
    };

    return fetch(url, options);
}