import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getCurrentAccount, isModerator } from '../services/account';
import { getQuestion, removeQuestion } from '../services/services'
import { IQuestion, IQuestionState } from '../types';
import { Loader } from './Loader';
import { useNavigate } from 'react-router-dom';
import { User } from '@microsoft/microsoft-graph-types';
import { useTranslation } from 'react-i18next';
import { Header } from './Header';

export const QuestionView: React.FunctionComponent<{ user: User }> = () => {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = params.questionId;
  const isAdmin = isModerator();
  const currentAccount = getCurrentAccount();

  const [question, setQuestion] = useState<IQuestion>();

  useEffect(() => {
    if (id) {
      getQuestion(id).then(async (res) => { return res.json() }).then((o) => setQuestion(o));
    }
  }, [id]);

  if (!id) {
    navigate("/");
  }

  if (!question) return <Loader isLoading={!question} />;

  const isAllowedToModify = (question.creatorId === currentAccount?.localAccountId && question.state !== IQuestionState.Answered) || (isAdmin && question.state !== IQuestionState.Answered);
  const isAllowedToDelete = isAllowedToModify || isAdmin;

  return (
    <div className='contents'>
        <Header header={t("questions:questionView.header")} additionalCss="modules-background"/>
        {question && id && (
          <React.Fragment>
              <small dangerouslySetInnerHTML={{ __html: t("questions:questionView.questionAskedBy", {
                  "createdBy": question.creatorDisplayName,
                  "created": new Date(question.created as string).toLocaleDateString()
                })
              }} />
            <br /><br />
          </React.Fragment>
        )}
        {question.answer && question.answer.url && (
          <article className="message is-primary">
            <div className="message-body" dangerouslySetInnerHTML={{ __html: t("questions:questionView.questionAnsweredBy", {
                  "answeredBy": question.answer?.answeredByDisplayName,
                  "answered": new Date(question.answer?.answerDate as string).toLocaleDateString(),
                  "url": question.answer?.url
                })
              }}>
            </div>
          </article>
        )}
        <b>{question.title}</b><br />
        {question.description}
        <br />
        <br />
        <b>{question.titleEn}</b><br />
        {question.descriptionEn}
        <br />
        <br />
        <div className='footerButtons'>
            <div className='buttons is-pulled-right'>
              <Link to="/" className='button is-small'>
                {t("questions:questionView.buttons.back.text")}
              </Link>
              {isAdmin && (
                <button className='button is-small' onClick={() => { 
                  navigate(`/questions/${id}/answer`)
                }}>{t("questions:questionView.buttons.editanswer.text")}</button>
              )}
              {isAllowedToModify && (
                <button className='button is-small' onClick={() => { 
                  navigate(`/questions/${id}/edit`)
                }}>{t("questions:questionView.buttons.editquestion.text")}</button>
              )}
              {isAllowedToDelete && (
                <button className='button is-danger is-small' onClick={(e) => { 
                  e.preventDefault()
                  if (window.confirm("Are you sure you want to delete this question?")) {
                    removeQuestion(id as string).then(async (value) => {
                      if (value.ok) {
                        navigate('/');
                      }
                    })
                  }
                }}>{t("questions:questionView.buttons.delete.text")}</button>
              )}
          </div>
        </div>
    </div>
  );
};
