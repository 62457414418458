import React from 'react';

export const Acronym: React.FunctionComponent<{
  title: string;
  maxChars?: number;
}> = (prop) => {
  let { title } = prop

  let acronym = undefined;
  const max = prop.maxChars ? prop.maxChars : 2;

  if (title) {
    title = title.replace(/[^a-zA-Z ]/g, "")

    acronym = title
      .split(/\s/)
      .reduce(
        (response, word, index) =>
          index < max ? (response += word.slice(0, 1)) : response,
        ""
      )?.toUpperCase();
  }
  return <React.Fragment>{acronym}</React.Fragment>;
};
