import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isModerator } from '../services/account';
import { getQuestion } from '../services/services'
import { IQuestion } from '../types';
import { Loader } from './Loader';
import { AnswerDetail } from "./AnswerDetail"
import { User } from '@microsoft/microsoft-graph-types';
import { useTranslation } from 'react-i18next';

export const AnswerEditMask: React.FunctionComponent<{ user: User }> = (props) => {
  const params = useParams();
  const { t } = useTranslation();
  const id = params.questionId;
  const isAdmin = isModerator();
  const { user } = props;

  const [question, setQuestion] = useState<IQuestion | undefined>();

  useEffect(() => {
    if (id) {
      getQuestion(id).then(async (res) => { return res.json() }).then((o) => setQuestion(o));
    }
  }, [id]);

  if (!isAdmin) return <React.Fragment>{t("questions:answermask.accessdenied")}</React.Fragment>
  if (!question) return <Loader isLoading={!question} />;

  return (
      <AnswerDetail id={id} answer={question.answer} user={user}  />
  );
};
