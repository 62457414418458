import React, { useEffect, useState } from 'react';
import { Loader } from './Loader'
import { useTranslation } from 'react-i18next';
import { getNotificationsettings, updateNotificationSettings } from '../services/services';
import { INotificationSettings } from '../types';

export const FollowPreferences: React.FunctionComponent<{ showModal: boolean, setShowModal: React.Dispatch<React.SetStateAction<boolean>> }> = (props) => {
  const { t } = useTranslation();
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ notificationSettings, setNotificationSettings ] = useState<INotificationSettings>();
  const { showModal, setShowModal } = props;

  useEffect(() => {
    if (showModal) { 
      const callData = () => {
        getNotificationsettings().then(async (value) => {
          const output = await value.json() as unknown as INotificationSettings;
          setNotificationSettings(output);
        }).finally(() => setIsLoading(false))
      }
      callData();
    }
  }, [showModal]);

  if (!showModal)
    return <React.Fragment />

  if (isLoading && showModal) {
    return <Loader isLoading={isLoading} />;
  }

  const onNotifyNewQuestions = (notificationSettings: INotificationSettings) => {
    updateNotificationSettings(notificationSettings).then(() => {
      setNotificationSettings({...notificationSettings});
    });
  }

  if (!notificationSettings){
    return <React.Fragment />
  }

  const setAnsweredQuestions = () => { notificationSettings.notifyAnsweredQuestions = !notificationSettings.notifyAnsweredQuestions; onNotifyNewQuestions(notificationSettings) }
  const setNewQuestion = () => { notificationSettings.notifyNewQuestions = !notificationSettings.notifyNewQuestions; onNotifyNewQuestions(notificationSettings) }
 
  return (<div className={showModal ? "modal is-active" : "modal"}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{t("questions:overview.followsettings.title")}</p>
              <button className="delete" aria-label="close" onClick={() => setShowModal(false)}></button>
            </header>
            <section className="modal-card-body">
              <p dangerouslySetInnerHTML={{ __html: t("questions:overview.followsettings.text.intro") }}>
              </p>  
              <br />
              <div className='columns'>
                <div className='column'>
                <h3 className="title is-5" dangerouslySetInnerHTML={{ __html: t("questions:overview.followsettings.textNewQuestions.intro") }} />
              <button className='button is-primary' onClick={setNewQuestion}>
                {notificationSettings.notifyNewQuestions ? t("questions:overview.followsettings.textNewQuestions.dontnotify") : t("questions:overview.followsettings.textNewQuestions.notify")}
              </button> 
                </div>
                <div className='column'>
                <h3 className="title is-5" dangerouslySetInnerHTML={{ __html: t("questions:overview.followsettings.textAnswerQuestions.intro") }} />
              <button className='button is-primary' onClick={setAnsweredQuestions}>
                {notificationSettings.notifyAnsweredQuestions ? t("questions:overview.followsettings.textAnswerQuestions.dontnotify") : t("questions:overview.followsettings.textAnswerQuestions.notify")}
              </button>
                </div>
              </div>              
            </section>            
            <footer className="modal-card-foot">
              <button className="button" onClick={() => setShowModal(false)}>{t("questions:overview.followsettings.cancel")}</button>
            </footer>
          </div>
      </div>);
};
