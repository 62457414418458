import React, { useEffect, useState } from 'react';
import './App.css';
import { Layout } from "./components/Layout"
import { getMe } from './services/services'
import { Route, Routes } from "react-router-dom";
import { QuestionOverview } from "./components/QuestionOverview"
import { QuestionDetail } from "./components/QuestionDetail"
import { QuestionView } from "./components/QuestionView"
import { User } from "@microsoft/microsoft-graph-types";
import { Loader } from './components/Loader';
import { AnswerEditMask } from "./components/AnswerEditMask"


const App: React.FC = () => {  
  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    const callData = () => {
      getMe().then((value) => {
        setUser(value);
      })
    }
    callData();
  }, [])

  if (!user) {
    return <Loader />
  }

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<QuestionOverview />} />
        <Route path="/:mode" element={<QuestionOverview />} />
        <Route path="/questions/create" element={<QuestionDetail user={user} />} />
        <Route path="/questions/:questionId" element={<QuestionView user={user} />} />
        <Route path="/questions/:questionId/edit" element={<QuestionDetail user={user} />} />
        <Route path="/questions/:questionId/answer" element={<AnswerEditMask user={user} />} />
      </Routes>
    </Layout>
  );
}

export default App;
